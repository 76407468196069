<aside class="aside-navbar">
  <figure class="sidebar-logo">
    <img src="assets/images/logo_white.png" alt="" />
  </figure>
  <ng-scrollbar class="custom-scrl" style="max-height: calc(100% - 103px)">
    <ul>
      <li class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/dashboard']"
          [routerLinkActive]="['dash-active']"
          [ngClass]="{ 'dash-active': currentUrl.includes('/dashboard') }"
          ><span><i class="bi bi-grid-fill"></i></span
          ><ng-container i18n>Dashboard</ng-container></a
        >
      </li>
      <li *ngIf="role_data.indexOf(1) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/customers/list']"
          [ngClass]="{ 'dash-active': currentUrl.includes('/customers') }"
          ><span><i class="bi bi-people-fill"></i></span
          ><ng-container i18n>manage customer</ng-container></a
        >
      </li>
      <li *ngIf="role_data.indexOf(12) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/partners/list']"
          [ngClass]="{ 'dash-active': currentUrl.includes('/partners') }"
          ><span><i class="bi bi-person-video2"></i></span
          ><ng-container i18n>manage partner</ng-container></a
        >
      </li>
      <li *ngIf="role_data.indexOf(10) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/category/list']"
          [ngClass]="{
            'dash-active':
              currentUrl.includes('/category/list') ||
              currentUrl.includes('/subcategory/list') ||
              currentUrl.includes('/product/list'),
          }"
          ><span><i class="bi bi-list-task"></i></span
          ><ng-container i18n>Product Management</ng-container></a
        >
      </li>
      <li *ngIf="role_data.indexOf(13) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/customer-executive']"
          [ngClass]="{
            'dash-active': currentUrl.includes('/customer-executive'),
          }"
          ><span><i class="bi bi-headset"></i></span
          ><ng-container i18n="@@custExecutive"
            >Customer Executive</ng-container
          ></a
        >
      </li>
      <li *ngIf="role_data.indexOf(9) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/agents']"
          [ngClass]="{ 'dash-active': currentUrl.includes('/agents') }"
          ><span
            ><img
              src="assets/images/agent_network.svg"
              alt="Agent Network" /></span
          ><ng-container i18n>Agent Network</ng-container></a
        >
      </li>
      <li *ngIf="role_data.indexOf(2) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/proposal']"
          [ngClass]="{ 'dash-active': currentUrl.includes('/proposal') }"
          ><span><i class="bi bi-clipboard-data-fill"></i></span
          ><ng-container i18n>Gestion des affaires</ng-container></a
        >
      </li>

      <li *ngIf="role_data.indexOf(2) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/referral']"
          [ngClass]="{ 'dash-active': currentUrl.includes('/referral') }"
          ><span><i class="bi bi-clipboard-data-fill"></i></span
          ><ng-container i18n>Gestion des parrainages</ng-container></a
        >
      </li>

      <li
        *ngIf="
          role_data.indexOf(6) !== -1 ||
          role_data.indexOf(7) !== -1 ||
          role_data.indexOf(8) !== -1
        "
        class="nav-item has-dropdown"
        [ngClass]="{ 'menu-open dash-active': currentUrl.includes('/task') }"
      >
        <!-- <ngb-accordion #acc="ngbAccordion">
          <ngb-panel id="toggle-1" title="Task">
            <ng-template ngbPanelContent> -->
        <a href="javascript:void(0);"
          ><span><i class="bi bi-pie-chart-fill"></i></span
          ><ng-container i18n>Task</ng-container></a
        >
        <ul>
          <li *ngIf="role_data.indexOf(6) !== -1">
            <a
              href="javascript:void(0);"
              [routerLink]="['/task/leads']"
              [ngClass]="{ 'dash-active': currentUrl.includes('/task/leads') }"
              ><ng-container i18n>leads</ng-container></a
            >
          </li>
          <li *ngIf="role_data.indexOf(7) !== -1">
            <a
              href="javascript:void(0);"
              [routerLink]="['/task/submission']"
              [ngClass]="{
                'dash-active': currentUrl.includes('/task/submission'),
              }"
              ><ng-container i18n>Submissions</ng-container></a
            >
          </li>
          <li *ngIf="role_data.indexOf(8) !== -1">
            <a
              href="javascript:void(0);"
              [routerLink]="['/task/agenda']"
              [ngClass]="{ 'dash-active': currentUrl.includes('/task/agenda') }"
              ><ng-container i18n>Agenda</ng-container></a
            >
          </li>
        </ul>
        <!-- </ng-template>
          </ngb-panel>
        </ngb-accordion> -->
      </li>
      <li *ngIf="role_data.indexOf(3) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/financial_statement']"
          [ngClass]="{
            'dash-active': currentUrl.includes('/financial_statement'),
          }"
          ><span
            ><img
              src="assets/images/financial_statement.svg"
              alt="financial statement" /></span
          ><ng-container i18n>financial statement</ng-container></a
        >
      </li>
      <li *ngIf="role_data.indexOf(11) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/role-access']"
          [ngClass]="{ 'dash-active': currentUrl.includes('/role-access') }"
          ><span><i class="bi bi-layers-fill"></i></span
          ><ng-container i18n>rules & access</ng-container></a
        >
      </li>
      <li *ngIf="role_data.indexOf(4) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/data-analysis']"
          [ngClass]="{ 'dash-active': currentUrl.includes('/data-analysis') }"
          ><span><i class="bi bi-layers-fill"></i></span
          ><ng-container i18n>Data Analysis & Extraction</ng-container></a
        >
      </li>

      <li *ngIf="role_data.indexOf(5) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/newsletter']"
          [ngClass]="{ 'dash-active': currentUrl.includes('/newsletter') }"
          ><span
            ><img
              src="assets/images/letter_management.svg"
              alt="newsletter" /></span
          ><ng-container i18n>newsletter</ng-container></a
        >
      </li>
      <li *ngIf="role_data.indexOf(3) !== -1" class="nav-item">
        <a
          href="javascript:void(0);"
          [routerLink]="['/contract-templates']"
          [ngClass]="{
            'dash-active': currentUrl.includes('/contract-templates'),
          }"
          ><span
            ><img
              src="assets/images/letter_management.svg"
              alt="newsletter" /></span
          ><ng-container i18n>Modèles de contrat</ng-container></a
        >
      </li>
      <!--
      <li class="nav-item"><a href="javascript:void(0);"><span><i class="bi bi-envelope-fill"></i></span>message</a></li>
      -->
    </ul>
  </ng-scrollbar>
</aside>
