import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserAuthService } from 'src/app/_services/user-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  VDATE_TIME_FORMAT = environment.VDATE_TIME_FORMAT;
  Admindetail: any;
  notificationList: any = [];
  totalNotification: any = 0;
  logoutSubscribeData: any;
  user: any;
  constructor(
    private userAuthService: UserAuthService,
    private router: Router,
    private toastrService: ToastrService
  ) {
    let data: any = localStorage.getItem('user');
    if (data != null) {
      this.user = JSON.parse(data);
    }
  }
  ngOnInit(): void {
    if (this.userAuthService.isUserLoggedIn()) {
      this.Admindetail = this.userAuthService.getCurrentUser();
      this.GetNotificationList();

      setInterval(() => {
        this.GetNotificationList();
      }, 60000);
    }
    this.GetProfileDetail();
  }
  _getProfile: any;
  _getLoggedInuser: any = {
    firstName: '',
    lastName: '',
  };
  GetProfileDetail(): any {
    this._getProfile = this.userAuthService.getAdminByID().subscribe({
      next: (x: any) => {
        this._getLoggedInuser = x.data;
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        console.error(err);
        localStorage.removeItem('user');
        this.router.navigate(['/login']);
      },
      complete: () => {},
    });
  }

  GetNotificationList(): any {
    this._getProfile = this.userAuthService.GetNotificationList().subscribe({
      next: (x) => {
        this.notificationList = x.data.notificationList;
        this.totalNotification = x.data.notificationList.filter(
          (x) => x.status === 'SENT'
        ).length;
      },
      error: () => {},
      complete: () => {},
    });
  }

  readNotifications(): any {
    this.userAuthService.readNotifications().subscribe({
      next: (x: any) => {
        this.totalNotification = 0;
      },
    });
  }

  SignOut(): void {
    let obj = {
      token: this.user.token,
      hardSignOut: true,
    };
    this.logoutSubscribeData = this.userAuthService._logout(obj).subscribe({
      next: (result: any) => {
        if (result.status == 'SUCCESS') {
          localStorage.clear();
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 2000);
        }
      },
      error: (err: any) => {
        let errRes: any;
        errRes = err;
        this.toastrService.error(errRes.error.error.clientErrorMessage);
      },
      complete: () => {},
    });
  }
}
