<header class="header-outer overflow-visible">
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <a class="navbar-brand" href="#"
        ><img src="assets/images/logo.png" alt="LTY" i18n
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#mainNavbar"
        aria-controls="mainNavbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="bi bi-list"></i>
      </button>
      <div class="collapse navbar-collapse" id="mainNavbar">
        <ul class="navbar-nav m-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" href="javascript:void(0);" i18n>Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" i18n>Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" i18n>About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javasript:void(0);" i18n>How it works</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" i18n>Support</a>
          </li>
        </ul>
      </div>
      <div class="top-btn-group d-flex align-items-center">
        <a class="btn btn-primary readmore" [routerLink]="['/login']" i18n
          >Login</a
        >
        <div class="ms-1">
          <app-language-switcher></app-language-switcher>
        </div>
      </div>
    </div>
  </nav>
</header>
