<div class="dashboard-header">
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <!--<form class="d-flex header-form-srch" role="search">
        <button class="btn-search"><i class="bi bi-search"></i></button>
        <input class="form-control" type="search" placeholder="Search" aria-label="Search">
      </form>-->
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="nav-link btn-notification"
              (click)="readNotifications()"
              aria-current="page"
              href="javascript:void(0)"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
              ><i class="bi bi-bell-fill"></i
              ><sup>{{ totalNotification }}</sup></a
            >
          </li>
          <li class="nav-item">
            <div class="dropdown lang-dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  ><img
                    *ngIf="_getLoggedInuser.profilePic != null"
                    src="{{
                      _getLoggedInuser.profilePic
                        ? _getLoggedInuser.profilePic
                        : 'assets/images/userimg.png'
                    }}"
                    alt="Mulopo Kikunda" /><img
                    *ngIf="_getLoggedInuser.profilePic == null"
                    src="assets/images/userimg.png"
                    alt="Mulopo Kikunda"
                /></span>
                {{ _getLoggedInuser.firstName }} {{ _getLoggedInuser.lastName }}
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" [routerLink]="['dashboard/profile']"
                    ><span><i class="bi bi-person-fill"></i></span
                    ><ng-container i18n>My Account</ng-container>
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    (click)="SignOut()"
                    ><span><i class="bi bi-box-arrow-right"></i></span
                    ><ng-container i18n>Logout</ng-container>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div class="ms-1">
          <app-language-switcher></app-language-switcher>
        </div>
      </div>
    </div>
  </nav>
</div>

<div
  class="offcanvas offcanvas-end notificaton-offcanvas"
  tabindex="-1"
  id="offcanvasRight"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel" i18n>Notifications</h5>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    >
      <i class="bi bi-x-lg"></i>
    </button>
  </div>
  <div class="offcanvas-body">
    <ul>
      <li *ngFor="let notification of notificationList">
        <div class="d-flex align-items-center">
          <!--<figure class="notification-img"><img src="assets/images/person01.png"
                alt=""></figure>-->
          <div class="notification-content">
            <p class="notification-title d-block mb-1">
              <strong>{{ notification.title }}</strong> {{ notification.body }}
            </p>
            <small>{{
              notification.createdOn | date: VDATE_TIME_FORMAT
            }}</small>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
