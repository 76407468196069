import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { UserAuthService } from 'src/app/_services/user-auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  currentUrl: any = [];
  role_data: any = [];
  private _getAccount: any;
  auth: any = '';
  constructor(
    private router: Router,
    private userAuthService: UserAuthService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnInit(): void {
    $(document).on('click', '.nav-item>a', function (e) {
      $(this)
        .parent()
        .toggleClass('menu-open')
        .siblings()
        .removeClass('menu-open');
    });
    this.GetProfileDetail();
  }

  ngAfterViewInit(): void {}
  GetProfileDetail(): any {
    this._getAccount = this.userAuthService.getAdminByID().subscribe({
      next: (x: any) => {
        let res = x.data;
        this.auth = res;
        if (this.auth.type === 'ADMIN') {
          this.role_data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
        } else {
          this.role_data = res.roleId;
        }
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
      },
      complete: () => {},
    });
  }
}
