import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from '../safehtml.pipe';
import { FileDragDropDirective } from './_directives/file-drag-drop.directive';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { SharedRoutingModule } from './shared-routing.module';
import { AddCommissionModalComponent } from './components/add-commission-modal/add-commission-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//import { CustomerNavComponent } from './components/customer-nav/customer-nav.component';
//import { DataAnalysisNavComponent } from './components/data-analysis-nav/data-analysis-nav.component';
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    FileDragDropDirective,
    SafeHtmlPipe,
    LanguageSwitcherComponent,
    AddCommissionModalComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    FileDragDropDirective,
    SafeHtmlPipe,
    LanguageSwitcherComponent,
    AddCommissionModalComponent,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation],
    },
  ],
})
export class SharedModule {}
